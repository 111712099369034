<!--
 * @Description:我的 个人信息 手机号修改 selfChangePhone
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-31 10:33:59
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="手机换绑"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="lineOne">
      <img src="@/assets/mySelf/phone.png" />
    </van-row>
    <van-row class="lineTwo">
      <span>{{phoneNumber}}</span>
    </van-row>
    <van-field v-model="sendPinForm.phoneNum"
               placeholder="请输入手机号">
      <template #left-icon>
        <img src="@/assets/mySelf/shoujihao.png"
             class="img">
      </template>
    </van-field>
    <van-field v-model="confirmForm.pinNumber"
               center
               placeholder="请输入短信验证码">
      <template #left-icon>
        <img src="@/assets/mySelf/yanzhengCode.png"
             class="img">
      </template>
      <template #button>
        <van-button size="small"
                    class="button"
                    :disabled="pinButDisabled"
                    @click="seedPin">{{pinButText}}</van-button>
      </template>
    </van-field>
    <van-button @click="handleClickConfirm"
                class="confirmBut-box">
      确认更换
    </van-button>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      phoneNumber: '15651201254', // 当前手机号
      userInfo: {}, // 用户信息
      sendPinForm: {
        phoneNum: ''
      },
      confirmForm: {
        phoneNum: '',
        pinNumber: ''
      },
      pinButDisabled: false,
      pinButText: '发送验证码',
      second: 60
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.phoneNumber = this.$route.query.phoneNumber
    this.userInfo = this.$cookie.get('userInfoDetails')
    console.log(this.userInfo, 'this.userInfothis.userInfothis.userInfothis.userInfo')
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 发送验证码
    seedPin () {
      const phone = this.sendPinForm.phoneNum
      const regex = /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/
      if (!regex.test(phone)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      // 判断手机号是否已经绑定
      const info = {
        phoneNumber: phone
      }
      this.$selfInfo.changeBindPhoneNumber(info).then(res => {
        this.pinButDisabled = true // 发送按钮禁用
        console.log('发送验证码', this.sendPinForm)
        this.$selfInfo.sendSms(this.sendPinForm).then(() => {
          this.$toast('发送成功')
          this.confirmForm.phoneNum = this.sendPinForm.phoneNum
          this.pinButText = `重新发送 ${this.second}s`
          this.setButTime()
        }).catch(() => {
          this.$toast.fail('发送失败')
          this.pinButDisabled = false
        })
      }).catch(err => {
        console.log(err)
      })
    },
    // 确认更换按钮
    handleClickConfirm () {
      if (!this.confirmForm.pinNumber && !this.confirmForm.phoneNum) {
        this.$toast('请输入手机号或验证码')
        return false
      }
      console.log('发送绑定请求', this.confirmForm)
      this.$selfInfo.changePhoneNumber(this.confirmForm).then(res => {
        console.log('绑定返回', res)
        this.userInfo.phoneNumber = this.confirmForm.phoneNum
        console.log(' this.userInfo this.userInfo this.userInfo', this.userInfo)
        this.$cookie.set('userInfoDetails', this.userInfo)
        this.$toast({ message: '绑定成功' })
        this.$router.go(-1)
      })
    },
    // 倒计时设定
    setButTime () {
      const ctrl = setInterval(() => {
        this.second--
        this.pinButText = `重新发送 ${this.second}s`
        if (this.second <= 0) {
          clearInterval(ctrl)
          this.pinButDisabled = false
          this.pinButText = '发送验证码'
        }
      }, 1000)
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;

  .lineOne {
    padding-top: 20px;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
  }
  .lineTwo {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    color: #ffffff;
    background: #19a9fc;
  }
  .img {
    padding-top: 3px;
    padding-right: 10px;
  }
  .confirmBut-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 93.6%;
    margin-left: 3.2%;
    height: 40px;
    margin-top: 30px;
    background: #19a9fc;
    border-radius: 5px;
    font-size: 17px;
    line-height: 40px;
    font-weight: Regular;
    text-align: center;
    color: #ffffff;
  }
}
</style>
